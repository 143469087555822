import { default as indexFCBv1Fu6ayMeta } from "/opt/build/repo/pages/activity/[parent]/[slug]/index.vue?macro=true";
import { default as indexCvqKFUPAkdMeta } from "/opt/build/repo/pages/activity/[parent]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as newsletter4iem4JeVdNMeta } from "/opt/build/repo/pages/newsletter.vue?macro=true";
import { default as component_45stubcPkzMVLjTsMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcPkzMVLjTs } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "activity-parent-slug___it",
    path: "/:parent()/:slug()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "activity-parent___it",
    path: "/:parent()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___it",
    path: "/newsletter",
    component: () => import("/opt/build/repo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: component_45stubcPkzMVLjTsMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubcPkzMVLjTs
  }
]