import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'

export const useLenis = () => {
  const lenis = ref(null)

  onMounted(() => {
    lenis.value = new Lenis()
    lenis.value.on('scroll', ScrollTrigger.update)
    gsap.ticker.add((time) => {
      lenis.value.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)
  })

  onUnmounted(() => {
    lenis.value.destroy()
  })

  return { lenis }
}
