<template>
  <div>
    <NuxtPwaManifest />
    <NuxtLayout>
      <NuxtLoadingIndicator :color="'#000'" />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const { lenis } = useLenis()
provide('lenis', lenis)

onMounted(() => {
  lenis.value.scrollTo(0, { immediate: true })
})
</script>
