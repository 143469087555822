import revive_payload_client_vX8dGSfZxd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lqNCysQvcv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yuLF9jLWpF from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_80ZjXFvbg8 from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.21.2_vite@5.4.3_vue@3.5.4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_TP2pGuUtYh from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0a0gvKPhpN from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_iOwYb3pbvD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_eUgfN3lT0h from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Rd8LrchgHh from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.21.2_typescript@5.5.4_vue@3.5.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_3t6zh2d0p2 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_eslint@8.57.0_rollup@4.21.2_sass@1.78.0_stylelint@14.16.1_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_fJlDd9k4l1 from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.1_rollup@4.21.2_vue@3.5.4/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_iVJYXbdun4 from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_rollup@4.21.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_jlWpQRfXKm from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_rollup@4.21.2_vite@5.4.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_1VydY9VlUf from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_rollup@4.21.2_typescript@5.5.4_vue@3.5.4/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_tUgMPcR7pu from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.2_vue@3.5.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_cujllhsjvj from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.2_vue@3.5.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import directives_8CcCirWtnE from "/opt/build/repo/plugins/directives.ts";
export default [
  revive_payload_client_vX8dGSfZxd,
  unhead_lqNCysQvcv,
  router_yuLF9jLWpF,
  _0_siteConfig_80ZjXFvbg8,
  payload_client_TP2pGuUtYh,
  navigation_repaint_client_0a0gvKPhpN,
  check_outdated_build_client_iOwYb3pbvD,
  chunk_reload_client_eUgfN3lT0h,
  plugin_vue3_Rd8LrchgHh,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3t6zh2d0p2,
  plugin_fJlDd9k4l1,
  nuxt_plugin_iVJYXbdun4,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_jlWpQRfXKm,
  plugin_1VydY9VlUf,
  switch_locale_path_ssr_tUgMPcR7pu,
  i18n_cujllhsjvj,
  apollo_IRWTEOPleP,
  directives_8CcCirWtnE
]