<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <VitePwaManifest />
      <div class="page-error">
        <div class="row-1 p-top-3 p-bottom-3 page-error__content">
          <h1
            class="text-style-center title-m text-style-uppercase"
            v-html="$t('error.general')"
          />
          <nuxt-link
            :to="
              localePath({
                name: 'index',
              })
            "
            class="button-round-1 --orange m-top-24px"
          >
            <span>{{ $t('error.back') }}</span>
          </nuxt-link>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
<script setup>
import { useStore } from '@/store/index.js'

const props = defineProps({
  error: Object,
})
console.error(props.error)

await useAsyncData(() => useStore().fetchState())

const { lenis } = useLenis()
provide('lenis', lenis)

onMounted(() => {
  lenis.value.scrollTo(0, { immediate: true })
})
</script>
