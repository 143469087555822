// https://nuxt.com/docs/guide/directory-structure/plugins#vue-directives
// https://vuejs.org/guide/reusability/custom-directives.html
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('directive-test', {
    mounted(el) {
      console.log('directive', el)
    },
    getSSRProps(binding, vnode) {
      // you can provide SSR-specific props here
      console.log('SSR directive props')
      return {}
    },
  })
})
