import { defineStore } from 'pinia'

export const useStore = defineStore('store', () => {
  const state = ref({})

  const setState = (value) => {
    state.value = value
  }

  return {
    state,
    setState,
  }
})
